<template>
  <div>
    <div v-if="!tooBig">
      <div class="text-muted">
        *you can rotate and zoom this chart using your mouse
      </div>
      <div id="chartholder" height="600px">
        <span class="text-danger"
          >If you don't see the chart try reloading the page or select another
          data set.</span
        >
      </div>
    </div>
    <div v-if="tooBig" class="text-danger">Data is too large for the chart</div>
  </div>
</template>

<script>
export default {
  name: "BarChart3d",

  props: {
    tableData: Object,
    numRowHeaders: Number,
    sortBy: Number,
  },
  watch: {
    tableData: {
      deep: true,
      handler(newData) {
        this.processData(newData);
        this.makeChart();
      },
    },
    sortBy: function () {
      this.processData(this.tableData);
      this.makeChart();
    },
  },
  data: function () {
    return {
      tooBig: false,
      chartOptions: {},
      series: [],
      headers: [],
      dataTable: [],
      maxRowsToShow: 20,
      maxGrowth: 0,
    };
  },
  computed: {},
  mounted: function () {},
  methods: {
    processData(newData) {
      if (!newData || newData.data.length === 0) {
        return;
      }

      const X = newData.data[0] ? newData.data[0].length : 1;

      let sortKey = this.sortBy >= 0 && this.sortBy < X ? this.sortBy : X - 1;

      const sorting = function (a, b) {
        //asc order
        return a[sortKey] > b[sortKey] ? 1 : -1;
      };

      let data = [...newData.data].sort(sorting);
      this.series = [];
      this.headers = [];
      this.dataTable = [];
      this.maxGrowth = 0;

      //dates are x-axis
      //lineages/mutations are on z axis = series
      //values are on y axis

      const L = data.length;
      this.rowsToKeep = this.maxRowsToShow > L ? L : this.maxRowsToShow;

      for (let i = L - this.rowsToKeep; i < L; i++) {
        let row = data[i];
        let r = { stack: i };
        r.data = [];

        let start = this.numRowHeaders ? this.numRowHeaders : 0;
        r.name = this.numRowHeaders > 0 ? row[0] : "";
        this.series.push(r.name);

        // skip header, if any,  and last elements
        for (let j = start; j < row.length - 1; j++) {
          r.data.push({ x: j - start, y: row[j] });
          this.maxGrowth = this.maxGrowth > row[j] ? this.maxGrowth : row[j];
        }

        this.dataTable.push(r);
      }

      // this.series = data.map((x) => x[0]);
      this.headers = this.tableData.headers.map((x) => x.label);
      for (let h = 0; h < this.numRowHeaders; h++) {
        this.headers.shift();
      }
      this.headers.pop(); // remove last element
    },
    makeChart() {
      if (!this.dataTable) return;
      const $ = window.jQuery;
      const holder = $("#chartholder");
      if (!holder.length) return;
      const self = this;

      const columnDepth = 100;
      const options = {
        chart: {
          type: "column",
          colorCount: 50,
          options3d: {
            enabled: true,
            alpha: 20,
            beta: 30,
            depth: columnDepth * self.series.length, // Set depth
            viewDistance: 10,
            frame: {
              bottom: {
                size: 1,
                color: "rgba(0,0,0,0.05)",
              },
            },
          },
        },
        title: {
          text: "Recent growth (top " + self.rowsToKeep + " variants)",
        },
        yAxis: {
          min: 0,
          max: self.maxGrowth + 1,
        },
        xAxis: {
          min: 0, // Set min on xAxis
          max: self.headers.length - 1,
          gridLineWidth: 1,
          categories: self.headers,
          labels: {
            y: 5,
            rotation: -18,
          },
        },
        zAxis: {
          min: 0,
          max: self.series.length - 1,
          categories: self.series,
          labels: {
            y: 5,
            x: 50,
            rotation: 18,
          },
        },
        legend: { enabled: false },
        plotOptions: {
          series: {
            groupZPadding: 5,
            depth: columnDepth,
            groupPadding: 0,
            grouping: false,
          },
        },
        series: self.dataTable,
      };

      const chart = window.Highcharts.chart("chartholder", options);

      // Add mouse events for rotation
      $(chart.container).on("mousedown.hc touchstart.hc", function (eStart) {
        eStart = chart.pointer.normalize(eStart);

        var posX = eStart.pageX,
          posY = eStart.pageY,
          alpha = chart.options.chart.options3d.alpha,
          beta = chart.options.chart.options3d.beta,
          newAlpha,
          newBeta,
          sensitivity = 5; // lower is more sensitive

        $(document).on({
          "mousemove.hc touchdrag.hc": function (e) {
            // Run beta
            newBeta = beta + (posX - e.pageX) / sensitivity;
            chart.options.chart.options3d.beta = newBeta;

            // Run alpha
            newAlpha = alpha + (e.pageY - posY) / sensitivity;
            chart.options.chart.options3d.alpha = newAlpha;

            chart.redraw(false);
          },
          "mouseup touchend": function () {
            $(document).off(".hc");
          },
        });
      });
    },
  },
};
</script>

<style scoped>
</style>
